import { Dominated0, Dominated1, Dominated1n, Dominated2, Dominated2n, Dominated3, Dominated3n } from "assets/Imports";

export function ProgressAnimation(props) {
	function returnMuscle(dominated) {
		switch (dominated) {
			case 3:
				return (
					<>
					<img className="m-auto" src={Dominated3} alt="imagen de musculo color verde oscuro" style={{ width: 70 }} />
					<p>Dificultad: muy fácil</p>
					</>
				);
				break;
			case 2:
				return (
					<>
					<img className="m-auto" src={Dominated2} alt="imagen de musculo color verde verde" style={{ width: 70 }} />
					<p>Dificultad: fácil</p>
					</>
				);
				break;
			case 1:
				return (
					<>
					<img className="m-auto" src={Dominated1} alt="imagen de musculo color verde claro" style={{ width: 70 }} />
					<p>Dificultad: intermedia baja</p>
					</>
				);
				break;
			case 0:
				return (
					<>
					<img className="m-auto" src={Dominated0} alt="imagen de musculo color verde amarillo" style={{ width: 70 }} />
					<p>Dificultad: intermedia</p>
					</>
				);
				break;
			case -1:
				return (
					<>
					<img className="m-auto" src={Dominated1n} alt="imagen de musculo color naranja claro" style={{ width: 70 }} />
					<p>Dificultad: intermedia alta</p>
					</>
				);
				break;
			case -2:
				return(
					<>
					<img className="m-auto" src={Dominated2n} alt="imagen de musculo color rojo" style={{ width: 70 }} />
					<p>Dificultad: difícil</p>
					</>
				);

				break;
			case -3:
				return (
					<>
					<img className="m-auto" src={Dominated3n} alt="imagen de musculo color rojo oscuro" style={{ width: 70 }} />
					<p>Dificultad: muy difícil</p>
					</>
				);
				break;

			default:
				return (
					<>
					<img className="m-auto" src={Dominated0} alt="imagen de musculo color amarillo" style={{ width: 70 }} />;
					<p>Dificultad: intermedia</p>
					</>
				)
				break;
		}
	}
	return (
		<>
			<div className="domination-image-container text-center">{returnMuscle(props.dominated)}</div>
			<div className="domination-progress-word-container my-3">
				<div className="domination-balls-container">
					<div
						className={
							"progress-ball one " +
							(props.dominated === -3
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === -3
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball two " +
							(props.dominated === -2
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === -2
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball three " +
							(props.dominated === -1
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === -1
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball four " +
							(props.dominated === 0
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === 0
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball five " +
							(props.dominated === 1
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === 1
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball six " +
							(props.dominated === 2
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === 2
								? "disactive"
								: "nothing")
						}
					></div>
					<div
						className={
							"progress-ball seven " +
							(props.dominated === 3
								? props.dominated === props.lastDominated
									? "activeBlink"
									: "active"
								: props.lastDominated === 3
								? "disactive"
								: "nothing")
						}
					></div>
				</div>
			</div>
		</>
	);
}
