export type Nullable<T> = T | null;

export interface SeminaryRecording {
	id: string;
	num: number;
	videoUrl?: string;
	videoUrl2?: string;
	date: string;
	files: Array<SeminaryFilesArray>;
	notes: string;
	usersViews: Array<userViews>;
	totalViews: number;
	usersViews2: Array<userViews>;
	totalViews2: number;
	usersViews3: Array<userViews>;
	totalViews3: number;
	videoUrl12?: string;
	videoUrl13?: string;
	temaryIndex: number;
}

export interface userViews {
	userId: string;
	views: number;
}

interface SeminaryFilesArray {
	linkFile: string;
	name: string;
}

export interface languagesArrays {
	french: Array<language>;
	german: Array<language>;
	japanese: Array<language>;
	chinese: Array<language>;
	portuguese: Array<language>;
	russian: Array<language>;
	italian: Array<language>;
	english: Array<language>;
}

export interface languageProgress {
	french: {
		seen: number;
		dominated: number;
	};
	german: {
		seen: number;
		dominated: number;
	};
	japanese: {
		seen: number;
		dominated: number;
	};
	chinese: {
		seen: number;
		dominated: number;
	};
	italian: {
		seen: number;
		dominated: number;
	};
	russian: {
		seen: number;
		dominated: number;
	};
	portuguese: {
		seen: number;
		dominated: number;
	};
	english: {
		seen: number;
		dominated: number;
	};
}

export interface language {
	id: number;
	category: Array<string>;
	sub_category: Array<string>;
	dominated: Nullable<number>;
	language: string;
	native_word: string;
	optional_word: string;
	meaning: Array<string>;
	difficult: number;
	timesSeen: number;
	sub_categoria?: Array<string>;
	times_answered: number;
	times_correct_answer: number;
	custom_difficulty: string;
}

// Definir constantes para los tipos de filtros
export enum FilterType {
	NEW_WORDS = 'newWords',
	TO_LEARN = 'toLearn',
	DOMINATED = 'dominated',
	PERIOD = 'period'
  }
  
  // Nuevo tipo para representar los filtros
  interface Filters {
	include: Set<FilterType>;
	exclude: Set<FilterType>;
  }
  
  export interface lastWordsObject {
	filters: Filters;
	answerType: string;
  }

export interface segmentsQuiz {
	0: Array<quizWords>;
	1: Array<quizWords>;
	2: Array<quizWords>;
	3: Array<quizWords>;
	4: Array<quizWords>;
	5: Array<quizWords>;
	6: Array<quizWords>;
	7: Array<quizWords>;
}

export interface quizWords {
	warning: string;
	memory: string;
	advice: string;
	grammar: string;
	fake_friend: string;
	similarity: string;
	warning_type: string;
	category: Array<string>;
	singular_category: string;
	difficult: number;
	dominated: Nullable<number>;
	id: number;
	language: string;
	meaning: Array<string>;
	native_word: string;
	optional_word: Nullable<string>;
	sub_category: Array<Nullable<string>>;
	checked?: boolean;
	answer?: string;
	segment?: number;
	audio?: string;
	word_id: number;
	timesSeen: number;
	noMore?: boolean;
	times_answered: number;
	times_correct_answer: number;
}
export interface quizWordsEditModal {
	warning: string;
	warning_extended: string;
	memory: string;
	memory_extended: string;
	advice: string;
	advice_extended: string;
	grammar: string;
	grammar_extended: string;
	fake_friend: string;
	fake_friend_extended: string;
	similarity: string;
	similarity_extended: string;
	warning_type: string;
	category: Array<string>;
	singular_category: string;
	difficult: number;
	dominated: Nullable<number>;
	id: number;
	language: string;
	meaning: Array<string>;
	native_word: string;
	optional_word: Nullable<string>;
	sub_category: Array<string>;
	checked?: boolean;
	answer?: string;
	segment?: number;
	audio?: string;
	word_id: number;
	timesSeen: number;
	noMore?: boolean;
	times_answered: number;
	times_correct_answer: number;
}

export interface Quizzes {
	id: string;
	answerType: string;
	finished: boolean;
	seminaryNum: number;
	userId: string;
	quizWords: segmentsQuiz;
	createdAt: { seconds: number; nanoseconds: number };
	finishedAt: Nullable<Date>;
	language: string;
	difficult: Array<string>;
	correctWords: Array<quizWords>;
	incorrectWords: Array<quizWords>;
	index: number;
	actualSegment: number;
	name: string;
	errorsCounter: number;
	timeToAnswer?: number;
	default?: boolean;
	finishedDefault?: boolean;
	acerteClicks: number;
	errorsWords: Array<quizWords>;
	dominatedWords: Array<quizWords>;
	acerteWords: Array<quizWords>;
}

export interface finishedQuiz {
	id: string;
	answerType: string;
	finished: boolean;
	seminaryNum: number;
	userId: string;
	quizWords: segmentsQuiz;
	createdAt: { seconds: number; nanoseconds: number };
	finishedAt: Nullable<Date>;
	language: string;
	difficults: Array<string>;
	correctWords: Array<quizWords>;
	incorrectWords: Array<quizWords>;
	index: number;
	actualSegment: number;
	name: string;
	timeToAnswer: number;
}

export interface langTextTest {
	correcto: string;
	incorrecto: string;
	termino: string;
	middleScreen: string;
	finalScreen: string;
	respuestaCorrecta: string;
	tuRespuesta: string;
	buttons: {
		acerte: string;
		continuar: string;
		revisar: string;
		nolose: string;
		repetir: string;
		terminar: string;
		saltar: string;
	};
	finalScreenSubtitle: string;
	terminosBloque: string;
	escribeEn: string;
	escribeTerminoCorrectamente: string;
}

export interface qualification {
	correct: boolean;
	answer?: string;
	correctAnswer: Array<string>;
	category: Array<string>;
	subCategory: Array<Nullable<string>>;
	nativeWord: string;
	word: quizWords;
}

export interface specialKeyboard {
	toUpper: boolean;
	active: boolean;
}

export interface seeWords {
	show: boolean;
	words: Array<quizWords>;
	type: string;
}

export interface IGalleries {
	id?: string;
	title: string;
	image: string;
	displayImage?: string;
	imageResponsive: string;
	displayImageResponsive?: string;
	imageUSD?: string;
	displayImageUSD?: string;
	imageResponsiveUSD?: string;
	displayImageResponsiveUSD?: string;
	order: number;
}
export interface IGallerySettings {
	interval: number;
}
export interface IGalleryData {
	settings: IGallerySettings;
	galleries: Array<IGalleries>;
}
