import React, { useState, useRef, useEffect, useCallback, SyntheticEvent } from "react";
import { DateRange } from "react-date-range";
import { MinuteIntervals, TimePeriod } from "../common/enums/studyPeriods.enum";
import {
	fr, // Francés
	de, // Alemán
	ja, // Japonés
	it, // Italiano
	ru, // Ruso
	zhCN, // Chino
	pt, // Portugués
	enUS, // Inglés
} from "date-fns/locale";
import { calculatePreviewDateRange } from "../functions/functions";
import { FilterType } from "interfaces/Interfaces";

interface props {
	period?: TimePeriod;
	selectPeriod?: (e: TimePeriod) => void;
	dateRange: DateRange;
	setMinutes: (e: MinuteIntervals) => void;
	lang: string;
	minutes: MinuteIntervals;
	handleSelect: (ranges: any) => void;
	studyDateRange: {
		startDate: string;
		endDate: string;
	};
	mode: "include" | "exclude";
	handleFilterChange: (
		filterType: FilterType,
		mode: "include" | "exclude",
		checked: boolean
	) => void;
}

export const CustomSelect = (props: props) => {
	const {
		period,
		selectPeriod,
		dateRange,
		lang,
		minutes,
		setMinutes,
		handleSelect,
		studyDateRange,
		mode,
		handleFilterChange,
	} = props;

	const [localPeriod, setLocalPeriod] = useState<TimePeriod>(TimePeriod.NONE);
	const [localMinutes, setLocalMinutes] = useState<MinuteIntervals>(MinuteIntervals.NONE);

	const getFirstDayOfMonth = () => {
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth(), 1);
	};

	const getLastDayOfMonth = () => {
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth() + 1, 0);
	};

	const [localDateRange, setLocalDateRange] = useState<DateRange>([
		{
			startDate: mode === "include" ? getFirstDayOfMonth() : null,
			endDate: mode === "include" ? getLastDayOfMonth() : null,
			key: "selection",
		},
	]);

	const { CUSTOM, PREVIOUS_DAY, PREVIOUS_WEEK, PREVIOUS_MONTH, NONE, LAST_MINUTES } = TimePeriod;
	const { NONE: NONE_MINUTES } = MinuteIntervals;
	const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
	const [isMinutesOpen, setIsMinutesOpen] = useState(false);

	const [isFocused, setIsFocused] = useState(false);
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const selectContainerRef = useRef<HTMLDivElement>(null);
	const dateRangeRef = useRef<HTMLDivElement>(null);
	const minutesRef = useRef<HTMLDivElement>(null);

	const MINUTE_OPTIONS = [
		{ value: 5, label: "5 minutos" },
		{ value: 10, label: "10 minutos" },
		{ value: 20, label: "20 minutos" },
		{ value: 25, label: "25 minutos" },
		{ value: 30, label: "30 minutos" },
	];

	useEffect(() => {
		setIsDateRangeOpen(localPeriod === CUSTOM && isFocused);
		// Resetear minutos cuando cambie el periodo
		if (localPeriod !== LAST_MINUTES) {
			setIsMinutesOpen(false);
		}
	}, [localPeriod, isFocused, CUSTOM, LAST_MINUTES]);

	useEffect(() => {
		if (dateRange && dateRange[0] && (dateRange[0].startDate || dateRange[0].endDate)) {
			setLocalDateRange([
				{
					startDate: dateRange[0].startDate,
					endDate: dateRange[0].endDate,
					key: "selection",
				},
			]);
		}
		if (minutes !== NONE_MINUTES) {
			setLocalMinutes(minutes);
		}
	}, [dateRange]);

	const handleSelectClick = (e: React.MouseEvent<HTMLSelectElement>) => {
		if (localPeriod === CUSTOM) {
			setIsFocused(true);
			setIsDateRangeOpen(true);
		} else if (localPeriod === LAST_MINUTES) {
			setIsMinutesOpen(true);
		} else {
			setIsSelectOpen(!isSelectOpen);
			setIsFocused(!isFocused);
		}
	};

	const localesByName = {
		french: fr,
		german: de,
		japanese: ja,
		italian: it,
		russian: ru,
		chinese: zhCN,
		portuguese: pt,
		english: enUS,
	};

	const handleMinutesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = Number(e.target.value) as MinuteIntervals;
		if (value === NONE_MINUTES) {
			setIsMinutesOpen(false);
			return;
		}
		setLocalMinutes(value);
		setMinutes(value);
		setIsMinutesOpen(false);

		if (localPeriod === LAST_MINUTES) {
			handleFilterChange && handleFilterChange(FilterType.PERIOD, mode, true);
		}
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const newValue = e.target.value as TimePeriod;

		setLocalPeriod(newValue);

		if (newValue === NONE) {
			selectPeriod && selectPeriod(newValue);
			handleFilterChange && handleFilterChange(FilterType.PERIOD, mode, false);

			// Importante: no resetear otros estados aquí
			return;
		}

		// Configurar estados locales sin afectar al otro modo (include/exclude)
		if (newValue === CUSTOM) {
			setIsFocused(true);
			setIsDateRangeOpen(true);
			setIsMinutesOpen(false); // Cerrar el dropdown de minutos cuando se selecciona CUSTOM
		} else if (newValue === LAST_MINUTES) {
			setIsFocused(false);
			setIsDateRangeOpen(false);
			setIsMinutesOpen(true);
		} else {
			setIsFocused(false);
			setIsDateRangeOpen(false);
			setIsMinutesOpen(false);
		}

		selectPeriod && selectPeriod(newValue);
		handleFilterChange && handleFilterChange(FilterType.PERIOD, mode, true);
	};

	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			!selectContainerRef.current?.contains(event.target as Node) &&
			!dateRangeRef.current?.contains(event.target as Node) &&
			!minutesRef.current?.contains(event.target as Node)
		) {
			setIsFocused(false);
			setIsSelectOpen(false);
			setIsDateRangeOpen(false);
			setIsMinutesOpen(false);
		}
	}, []);

	function formatDate(isoString: string): string {
		if (!isoString) return "";

		try {
			const date = new Date(isoString);

			// Usar UTC methods para obtener los componentes de la fecha
			const day = date.getUTCDate().toString().padStart(2, "0");
			const month = date.toLocaleString("es-ES", { month: "short", timeZone: "UTC" });
			const year = date.getUTCFullYear();

			return `${day}/${month}/${year}`;
		} catch (error) {
			console.error("Error formatting date:", error);
			return "";
		}
	}

	const renderPeriodOption = (periodType: TimePeriod) => {
		const labels = {
			[TimePeriod.PREVIOUS_DAY]: "Día anterior",
			[TimePeriod.PREVIOUS_WEEK]: "Semana anterior",
			[TimePeriod.PREVIOUS_MONTH]: "Mes anterior",
			[TimePeriod.CUSTOM]: "Personalizado",
			[TimePeriod.LAST_MINUTES]: "Últimos minutos",
		};

		// Si es el periodo seleccionado, usar las fechas del backend
		if (period === periodType && studyDateRange.startDate) {
			return `${labels[periodType]} (${formatDate(studyDateRange.startDate)} - ${formatDate(
				studyDateRange.endDate
			)})`;
		}

		//esta parte podría no ser necesaria hacerla de esta forma:
		const { exclude, include } = calculatePreviewDateRange(periodType);
		if (mode === "include" && include.startDate) {
			return `${labels[periodType]} (${formatDate(include.startDate)} - ${formatDate(
				include.endDate
			)})`;
		}

		if (mode === "exclude" && exclude.startDate) {
			return `${labels[periodType]} (${formatDate(exclude.startDate)} - ${formatDate(
				exclude.endDate
			)})`;
		}

		return labels[periodType];
	};

	const handleLocalDateSelect = (ranges: any) => {
		if (ranges.selection) {
			const newRange = [
				{
					startDate: ranges.selection.startDate,
					endDate: ranges.selection.endDate,
					key: "selection",
				},
			];

			setLocalDateRange(newRange);
			handleSelect(ranges);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [handleClickOutside]);

	useEffect(() => {
		if (period !== undefined) {
			setLocalPeriod(period);
		}
	}, [period]);

	return (
		<div ref={selectContainerRef} className={`position-relative`}>
			<div className="d-flex flex-wrap align-items-center gap-5 w-100" style={{ gap: "8px" }}>
				<label htmlFor={`period-select-${mode}`} className="mb-0 flex-shrink-0">
					Términos estudiados en:
				</label>
				<div className="position-relative flex-grow-1">
					<select
						id={`period-select-${mode}`}
						className="form-select w-70 sm:w-60"
						value={localPeriod}
						onChange={handleSelectChange}
						onClick={handleSelectClick}
						style={{ minHeight: "38px", fontSize: "1rem" }}
					>
						<option value={NONE}>Selecciona un periodo</option>
						<option value={PREVIOUS_DAY}>{renderPeriodOption(PREVIOUS_DAY)}</option>
						<option value={LAST_MINUTES}>
							{`Últimos minutos ${localMinutes ? ` (${localMinutes})` : ""}`}
						</option>
						<option value={PREVIOUS_WEEK}>{renderPeriodOption(PREVIOUS_WEEK)}</option>
						<option value={PREVIOUS_MONTH}>{renderPeriodOption(PREVIOUS_MONTH)}</option>
						<option value={CUSTOM}>
							{`Escoge rangos de fechas${
								studyDateRange?.startDate && studyDateRange?.endDate && localPeriod === CUSTOM
									? ` (${formatDate(studyDateRange?.startDate)} - ${formatDate(
											studyDateRange?.endDate
									  )})`
									: ""
							}`}
						</option>
					</select>
					{/* Dropdown de minutos */}
					{localPeriod === LAST_MINUTES && isMinutesOpen && (
						<div
							ref={minutesRef}
							className="position-absolute mt-2 bg-white rounded shadow-lg w-100"
							style={{ zIndex: 999 }}
						>
							<select
								id={`minutes-select-${mode}`}
								className="form-select w-100"
								value={localMinutes}
								onChange={handleMinutesChange}
								style={{
									minHeight: "38px",
									fontSize: "1rem",
									border: "none",
								}}
							>
								<option value={NONE_MINUTES}>Selecciona</option>
								{MINUTE_OPTIONS.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</select>
						</div>
					)}
				</div>
			</div>
			{isDateRangeOpen && (
				<div
					ref={dateRangeRef}
					className="position-absolute mt-2 bg-white rounded shadow-lg w-100 w-lg-auto start-0 start-lg-50 translate-lg-middle-x"
					style={{
						maxWidth: "fit-content",
						zIndex: 999,
					}}
					tabIndex={-1}
				>
					<DateRange
						rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]}
						direction="vertical"
						showMonthAndYearPickers={false}
						locale={localesByName[lang]}
						showDateDisplay={false}
						ranges={localDateRange}
						onChange={handleLocalDateSelect}
					/>
				</div>
			)}
		</div>
	);
};
