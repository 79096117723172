import { useEffect, useState } from "react";
import { Col, Form, Row, DropdownButton, Dropdown, ProgressBar } from "react-bootstrap";
import InputMask from "react-input-mask";
import "./styles.scss";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { db } from "initFirebase";
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useHistory } from "react-router";
import emailjs from "emailjs-com";
import { SolicitudCard } from "./components/RequestCard";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import { FolderIcon } from "assets/Imports";
import { useAuth } from "@hooks/useAuth";
import { GenerationTypesToSpanish } from "interfaces/Generations";

function PaymentUpdateContent({ user }) {
	const [validated, setValidated] = useState(false);
	const [image, setImage] = useState<any>("");
	const [payments, setPayments] = useState([]);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [formModalShow, setFormModalShow] = useState(false);
	//const [activeGenerations, setActiveGenerations] = useState<Array<string>>([]);
	const [selected, setSelected] = useState("Seleccionar");
	const [dateValue, setDateValue] = useState("");
	const [phoneValue, setPhoneValue] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [UserGeneration, setUserState] = useState<any>(null);
	let dropdownValues: any = [];
	dropdownValues = [
		"Tarjeta",
		"Efectivo",
		"OXXO Pay",
		"SPEI",
		"PayPal",
		"Transferencia",
		"Depósito bancario",
		"Otro",
	];

	const handleSelect = async (e) => {
		setSelected(e);
	};

	function compare(a, b) {
		if (a.createdAt > b.createdAt) {
			return -1;
		}
		if (a.createdAt < b.createdAt) {
			return 1;
		}
		return 0;
	}

	const isFormAnswered = () => {
		if (user.formAnswered === false && user.generationId !== "") {
			setFormModalShow(true);
		} else {
			setFormModalShow(false);
		}
	};

	const history = useHistory();
	let name;
	if (user !== null) {
		name = user?.name?.split(" ");
	}

	const userId = useAuth().user.id;

	const handleSubmit = async (e) => {
		setLoadingSubmit(true);
		const form = e.currentTarget;
		e.preventDefault();

		// Validaciones preliminares
		if (image.size > 2 * 1024 * 1024) {
			alert("La imagen no puede superar los 2 MB");
			//setValidated(true);
			setLoadingSubmit(false);
			return;
		}

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
			setLoadingSubmit(false);
			return;
		}

		if (image === "") {
			alert("Favor de subir la imagen de su comprobante.");
			setValidated(true);
			setLoadingSubmit(false);
			return;
		}

		if (form.date.value.length !== 10) {
			alert(
				"Por favor, ingresa la fecha de tu pago en el formato dd/mm/aaaa. Ejemplo: 25 de abril 2022 -> 25/04/2022"
			);
			setLoadingSubmit(false);
			return;
		}

		if (selected === "Seleccionar") {
			alert("Selecciona un medio de pago");
			setLoadingSubmit(false);
			return;
		}

		if (isPossiblePhoneNumber(form.phone.value) === false) {
			alert("El numero de celular es invalido.");
			setLoadingSubmit(false);
			return;
		}

		if (image.type !== "image/jpeg" && image.type !== "image/png" && image.type !== "image/jpeg") {
			alert("Por favor, sube solo archivos de imagen en formato JPEG, JPG o PNG.");
			setLoadingSubmit(false);
			return;
		}

		// Si todas las validaciones pasan, proceder con la subida de imagen y guardado de datos
		try {
			const date = new Date(Date.now());
			const payAt = new Date(Date.now());
			let datePay = form.date.value.split("/");
			payAt.setDate(datePay[0]);
			payAt.setMonth(datePay[1] - 1);
			payAt.setFullYear(datePay[2]);
			const amount = parseInt(form.amount.value.replace(/[^\d.-]/g, ""));

			// Iniciar la carga de la imagen
			const uploadTask = firebase.storage().ref(`images/${uuidv4()}`).put(image);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setUploadProgress(progress);
				},
				(error) => {
					alert("Error al subir la imagen: " + error.message);
					setLoadingSubmit(false);
				},
				async () => {
					try {
						const imageUrl = await uploadTask.snapshot.ref.getDownloadURL();

						// Safely handle the UserGeneration.type property
						let modalidadValue = "Sin modalidad";
						if (UserGeneration && UserGeneration.type) {
							modalidadValue = UserGeneration.type;
						}

						const data = {
							name: user?.name,
							amount: amount,
							email: user.email,
							phone: form.phone.value ?? user.phone ?? "Sin celular",
							date: payAt.valueOf(),
							image: imageUrl,
							concept: form?.concept?.value,
							verified: false,
							medio: selected,
							createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
							dateTime: new Date(),
							checked: false,
							userId: userId,
							type: "solicitado por usuario",
							modalidad: modalidadValue,
							invoiceStatus: "Pending",
						};

						const result = await db.collection("paymentHistory").add(data);

						await addDoc(collection(db, "notifications"), {
							name: user?.name,
							text: "<p>Solicitud de pago</p>",
							date: new Date(),
							userId: "admin",
							paymentHistoryId: result.id,
							userType: user.userType === 2 ? 2 : 1,
							seen: false,
							profileColor: user.color,
							type: "new-request-payment",
							check: false,
							sectionId: "NYKrjrHTHAj4Cp1KF32A",
						});

						sendEmail();

						// Mostrar mensaje de éxito y resetear el formulario
						setImage(""); // Si usas estado para manejar la imagen
						setSelected("Seleccionar"); // Reset para Dropdowns
						form.reset();
						setPhoneValue("");
						setDateValue("");
					} catch (error) {
						console.error("Error processing payment after upload:", error);
						alert("Ocurrió un error al procesar tu solicitud después de la carga de la imagen");
					} finally {
						// Always set loading to false to avoid UI being stuck
						setLoadingSubmit(false);
					}
				}
			);
		} catch (error: unknown) {
			console.error("Error in payment submission:", error);
			alert("Ocurrió un error al procesar tu solicitud: " + error);
			setLoadingSubmit(false);
		}
	};
	const getPayments = async () => {
		const q = query(collection(db, "paymentHistory"), where("userId", "==", userId));
		var array: any = [];
		const querySnaptshot = await getDocs(q);
		await Promise.all(
			querySnaptshot.docs.map((doc) => {
				array.push({
					...doc.data(),
					id: doc.id,
					message: doc.data().message !== undefined ? doc.data().message : "",
				});
			})
		);

		array.sort(compare);
		setPayments(array);
	};

	const getGen = async () => {
		try {
			// Only attempt to get generation data if user has a generationId
			if (!user.generationId || user.generationId === "") {
				setUserState({ id: "", type: "Sin modalidad" });
				return;
			}

			const genRef = doc(db, "generations", user.generationId);
			const snap = await getDoc(genRef);

			if (snap.exists()) {
				setUserState({ id: snap.id, ...snap.data() });
			} else {
				console.log("Generation document does not exist");
				setUserState({ id: "", type: "Sin modalidad" });
			}
		} catch (error) {
			console.error("Error fetching generation data:", error);
			setUserState({ id: "", type: "Sin modalidad" });
		}
	};

	useEffect(() => {
		getGen();
	}, [user.generationId]);

	const sendEmail = () => {
		const dataemail = {
			subject: "Nueva solicitud de pago",
			message: "Tienes una nueva solicitud de pago en Academia de Lenguas Internacionales",
		};

		alert(
			"Solicitud enviada con éxito.\r\n\nTe enviaremos un correo de confirmación una vez ésta sea aprobada. Revisa tu bandeja de entrada.\r\n\nSi no aparece, te recomendamos consultar la bandeja de spam."
		);
		emailjs
			.send("service_1kfzhtl", "template_33c8s4e", dataemail, "user_PPI0O8nofs9cbuJ3JRWlT")
			.then(
				function (res) {
					history.go(0);
				},
				function (error) {
					console.error(error);
				}
			);
	};

	useEffect(() => {
		isFormAnswered();
		getPayments();
	}, [loadingSubmit]);

	const handlePhoneChange = (value) => {
		console.log(value); // Esto te ayudará a ver qué está recibiendo del componente
		setPhoneValue(value);
	};
	const handleDateChange = (e) => {
		setDateValue(e.target.value);
	};

	useEffect(() => {
		if (uploadProgress === 100) {
			const timer = setTimeout(() => {
				setUploadProgress(0);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [uploadProgress]);

	return (
		<>
			<div>
				<h1>¡Bienvenido, {name[0]}!</h1>
			</div>
			<div>
				<h2>Solicitud de actualización de pago</h2>
				<p>
					Si has hecho alguna transacción que aún no se ve reflejada en la plataforma (por ejemplo
					PayPal, depósito o transferencia bancaria, depósito en tiendas OXXO, etc.), puedes
					solicitar una actualización a tu historial de pagos.
					<br />
					<br />
					Por favor, llena el siguiente formulario y uno de nuestros colaboradores analizará tu
					solicitud a la brevedad. <br /> <br /> En cuanto sea aprobada, verás el movimiento
					reflejado en tu historial de pagos.
				</p>
			</div>
			<div style={{ width: "50%", margin: "auto", marginBottom: 40, minWidth: 300 }}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					{user.matricula !== "" && (
						<Row className="input-row">
							<Col className="w-100 p-0">
								<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
									<Form.Label>
										<b>Modalidad</b>
									</Form.Label>
									<Form.Control
										readOnly
										type="text"
										className="form-control"
										value={
											UserGeneration === null
												? "Cargando..."
												: GenerationTypesToSpanish[UserGeneration?.type] || ""
										}
										style={{ backgroundColor: "#fff", color: "#212529" }}
									/>
									<Form.Control.Feedback type="invalid">
										Por favor ingresa un importe válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
					)}
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
								<Form.Label>
									<b>Medio de pago</b>
								</Form.Label>
								<DropdownButton
									alignRight
									title={selected}
									className="dropdown-container"
									id="dropdown-menu-align-right"
									onSelect={handleSelect}
								>
									{dropdownValues.map((item, index) => (
										<Dropdown.Item eventKey={item} className="w-100">
											{item}
										</Dropdown.Item>
									))}
								</DropdownButton>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un importe válido.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }}>
								<Form.Label>
									<b>Celular</b>
								</Form.Label>
								<PhoneInput
									placeholder="Ingresa un número de celular."
									labels={es}
									defaultCountry="MX"
									international={true}
									name="phone"
									id="phone"
									value={phoneValue}
									onChange={handlePhoneChange}
								/>
								{/*<Form.Control required type="text" name="phone" placeholder="+52 55 654 4637" />*/}
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un celular válido.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
								<Form.Label>
									<b>Importe (en MXN)</b>
								</Form.Label>
								<Form.Control required type="text" name="amount" placeholder="$0.00" />
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un importe válido.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }}>
								<Form.Label>
									<b>Fecha de pago</b>
								</Form.Label>
								<InputMask
									required
									className="form-control"
									type="text"
									name="date"
									placeholder="dd/MM/aaaa"
									mask="99/99/9999"
									maskChar=""
									value={dateValue}
									onChange={handleDateChange}
								></InputMask>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un año.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group
								className=""
								style={{ display: "flex", flexDirection: "column", margin: 0 }}
								controlId="formBasicImage"
							>
								<Form.Label>
									<b>
										Comprobante{" "}
										<span className="paymentUpdate-formats-picture-payment">{`(Formatos aceptados .png, .jpg y .jpeg)`}</span>
									</b>
								</Form.Label>

								<label htmlFor="file" className="btn-select-picture-payment-update">
									{image === "" ? "Seleccionar imagen" : image?.name}
									<img style={{ float: "right" }} src={FolderIcon} />
								</label>
								<Form.Control
									id="file"
									type="file"
									name="file"
									accept=".png, .jpg, .jpeg"
									style={{ visibility: "hidden", position: "absolute" }}
									onChange={(e) => {
										if ((e.target as HTMLInputElement).files![0]) {
											console.log((e.target as HTMLInputElement).files![0]);
											setImage((e.target as HTMLInputElement).files![0]);
										}
									}}
								/>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un nombre.
								</Form.Control.Feedback>
								{uploadProgress > 0 && (
									<>
										<p style={{ fontSize: "10px" }}> Subiendo tu comprobante...</p>
										<ProgressBar
											now={uploadProgress}
											label={`${Math.round(uploadProgress)}%`}
											className="background-progress progress"
										/>
									</>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicConcept">
								<Form.Label>
									<b>Concepto</b>
								</Form.Label>
								<Form.Control
									required
									as="textarea"
									rows={3}
									name="concept"
									placeholder="Pago por 10 sesiones"
									data-conekta="card[name]"
								/>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un concepto.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col style={{ textAlign: "center" }}>
							{!loadingSubmit ? (
								<button
									type="submit"
									className="tertiary return-btn-style"
									style={{ margin: "auto" }}
								>
									Enviar
								</button>
							) : (
								<div
									className="spinner-border text-danger"
									role="status"
									style={{ marginBottom: "1rem" }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Col>
					</Row>
				</Form>
			</div>
			<div>
				<h2>Solicitudes anteriores</h2>
				{payments.length > 0 ? (
					payments.map((payment, i) => {
						return <SolicitudCard data={payment} />;
					})
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default PaymentUpdateContent;
