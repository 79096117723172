import { toPlural } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import {
	Dominated0,
	Dominated1,
	Dominated1n,
	Dominated2,
	Dominated2n,
	Dominated3,
	Dominated3n,
} from "assets/Imports";
import { capitalizeFirstLetter } from "functions/Functions";
import { language, quizWords, segmentsQuiz } from "interfaces/Interfaces";
import { startOfDay, endOfDay, subDays, subWeeks, subMonths, format } from "date-fns";
import { TimePeriod } from "../common/enums/studyPeriods.enum";

export function getLangSpanish(word: string, answerType?: string) {
	if (answerType !== undefined && answerType === "normal") {
		return "Español";
	} else {
		switch (word) {
			case "french":
				return "Francés";
				break;
			case "italian":
				return "Italiano";
				break;
			case "german":
				return "Alemán";
				break;
			case "russian":
				return "Ruso";
				break;
			case "japanese":
				return "Japonés";
				break;
			case "chinese":
				if (answerType === "kanji") {
					return "Chino (Kanzi)";
				} else {
					return "Chino (Pinyin)";
				}
				break;
			case "portuguese":
				return "Portugués";
				break;
			case "english":
				return "Inglés";
				break;
			default:
				break;
		}
	}
}

export function getInformationByLanguage(language: string): string {
	switch (language) {
		case "english":
			return "Information";
			break;
		case "french":
			return "Information";
			break;
		case "japanese":
			return "情報 (じょうほう)";
			break;
		case "german":
			return "Information";
			break;
		case "italian":
			return "Informazione";
			break;
		case "russian":
			return "Информaция";
			break;
		case "chinese":
			return "信息 (xìnxī)";
			break;
		case "portuguese":
			return "Informação";
			break;
		default:
			return "Información";
			break;
	}
}

export async function getDynamicQuizName(words: segmentsQuiz) {
	let array = new Array(
		...words[0],
		...words[1],
		...words[2],
		...words[3],
		...words[4],
		...words[5],
		...words[6],
		...words[7]
	);
	let categoriesArray = new Array();

	await Promise.all(
		array.map((word) => {
			if (!categoriesArray.includes(word.category[0])) {
				categoriesArray.push(word.category[0]);
			}
		})
	);
	let name = "";
	await Promise.all(
		categoriesArray.map((cat, index) => {
			name +=
				(index === 0 ? capitalizeFirstLetter(cat) : cat) +
				(index === categoriesArray.length - 2
					? categoriesArray[index + 1][0] !== "i"
						? " y "
						: " e "
					: index < categoriesArray.length - 2
					? ", "
					: "");
		})
	);

	return name;
}

export async function getCategoriesByArray(words: Array<language>) {
	let array = new Array(...words);
	let categoriesArray = new Array();

	await Promise.all(
		array.map((word) => {
			if (!categoriesArray.includes(word.category[0])) {
				categoriesArray.push(word.category[0]);
			}
		})
	);

	return categoriesArray;
}

export function getLangSpanishSimple(word: string) {
	switch (word) {
		case "french":
			return "francés";
			break;
		case "italian":
			return "italiano";
			break;
		case "german":
			return "alemán";
			break;
		case "russian":
			return "ruso";
			break;
		case "japanese":
			return "japonés";
			break;
		case "chinese":
			return "chino";
			break;
		case "portuguese":
			return "portugués";
			break;
		case "english":
			return "inglés";
			break;
		default:
			return word;
			break;
	}
}

export function sortNativeWord(a, b) {
	a = (a.native_word ? a.native_word : a.palabra).replace(/"/g, "").toLowerCase();
	b = (b.native_word ? b.native_word : b.palabra).replace(/"/g, "").toLowerCase();
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	return 0;
}

export function getDifficultNumber(string: string, custom_difficulty: number) {
	switch (string) {
		case "very-easy":
			return custom_difficulty <= 1 && custom_difficulty > 0.9;
			break;
		case "easy":
			return custom_difficulty <= 0.9 && custom_difficulty > 0.8;
			break;
		case "normal":
			return custom_difficulty <= 0.8 && custom_difficulty > 0.7;
			break;
		case "normal-high":
			return custom_difficulty <= 0.7 && custom_difficulty > 0.5;
			break;
		case "hard":
			return custom_difficulty <= 0.5 && custom_difficulty > 0.3;
			break;
		case "expert":
			return custom_difficulty <= 0.3;
			break;
		default:
			return false;
			break;
	}
}

export function getParentsCategorys(categories: Array<string>) {
	let word = "";
	categories.map((cat, index) => {
		if (index > 0) {
			word += "/" + capitalizeFirstLetter(cat);
		} else {
			word += capitalizeFirstLetter(cat);
		}
	});

	return word;
}

export function returnSubCategories(ArrayWords: Array<language>) {
	let tempArray = new Array();

	ArrayWords.map((word) => {
		if (word.sub_category !== null) {
			(word.sub_category ? word.sub_category : word.sub_categoria!).map((subCategory) => {
				if (!tempArray.includes(subCategory)) {
					tempArray.push(subCategory);
				}
			});
		}
	});
	return tempArray;
}

export const difficults = ["very-easy", "easy", "medium-low", "medium-high", "hard", "expert"];

export function getDifficultColor(customDifficulty: number) {
	if (customDifficulty <= 1 && customDifficulty > 0.9) {
		return "#00FF00";
	} else if (customDifficulty <= 0.9 && customDifficulty > 0.8) {
		return "#e3ff00";
	} else if (customDifficulty <= 0.8 && customDifficulty > 0.7) {
		return "#c6ce00";
	} else if (customDifficulty <= 0.7 && customDifficulty > 0.5) {
		return "#FFC000";
	} else if (customDifficulty <= 0.5 && customDifficulty > 0.3) {
		return "#ff4000";
	} else if (customDifficulty <= 0.3) {
		return "#8B0000";
	} else {
		return "#ffffff";
	}
}

export function getDifficultColor2(customDifficulty: number) {
	// This function is used in VocabularySeminaryContainer.tsx
	if (customDifficulty <= 1 && customDifficulty > 0.9) {
		return "#00FF00";
	} else if (customDifficulty === null) {
		return "#00FFFF";
	} else if (customDifficulty <= 0.9 && customDifficulty > 0.8) {
		return "#e3ff00";
	} else if (customDifficulty <= 0.8 && customDifficulty > 0.7) {
		return "#c6ce00";
	} else if (customDifficulty <= 0.7 && customDifficulty > 0.5) {
		return "#FFC000";
	} else if (customDifficulty <= 0.5 && customDifficulty > 0.3) {
		return "#ff4000";
	} else if (customDifficulty <= 0.3) {
		return "#8B0000";
	} else {
		return "#ffffff";
	}
}

export function getArmByDifficultByNumber(customDifficulty: number) {
	if (customDifficulty <= 1 && customDifficulty > 0.9) {
		return <img src={Dominated3} alt="3" />;
	} else if (customDifficulty <= 0.9 && customDifficulty > 0.8) {
		return <img src={Dominated2} alt="2" />;
	} else if (customDifficulty <= 0.8 && customDifficulty > 0.7) {
		return <img src={Dominated1} alt="1" />;
	} else if (customDifficulty <= 0.7 && customDifficulty > 0.5) {
		return <img src={Dominated0} alt="-0" />;
	} else if (customDifficulty <= 0.5 && customDifficulty > 0.3) {
		return <img src={Dominated1n} alt="-1" />;
	} else if (customDifficulty <= 0.3) {
		return <img src={Dominated3n} alt="-3" />;
	} else {
		return "";
	}
}

export function getDominatedIconByNumber(dominatedNumber: number) {
	switch (dominatedNumber) {
		case -3:
			return <img src={Dominated3n} alt="-3" />;
		case -2:
			return <img src={Dominated2n} alt="-2" />;
		case -1:
			return <img src={Dominated1n} alt="-1" />;
		case 0:
			return <img src={Dominated0} alt="-0" />;
		case 1:
			return <img src={Dominated1} alt="1" />;
		case 2:
			return <img src={Dominated2} alt="2" />;
		case 3:
			return <img src={Dominated3} alt="3" />;
		default:
			return dominatedNumber;
	}
}

export function getDifficultString(customDifficulty: number) {
	if (customDifficulty <= 1 && customDifficulty > 0.9) {
		return "very-easy";
	} else if (customDifficulty <= 0.9 && customDifficulty > 0.8) {
		return "easy";
	} else if (customDifficulty <= 0.8 && customDifficulty > 0.7) {
		return "medium-low";
	} else if (customDifficulty <= 0.7 && customDifficulty > 0.5) {
		return "medium-high";
	} else if (customDifficulty <= 0.5 && customDifficulty > 0.3) {
		return "hard";
	} else if (customDifficulty <= 0.3 && customDifficulty !== null) {
		return "expert";
	} else {
		return "none";
	}
}

export function getDifficultStringSpanish(customDifficulty: number) {
	if (customDifficulty <= 1 && customDifficulty > 0.9) {
		return "Muy fácil";
	} else if (customDifficulty <= 0.9 && customDifficulty > 0.8) {
		return "Fácil";
	} else if (customDifficulty <= 0.8 && customDifficulty > 0.7) {
		return "Intermedia baja";
	} else if (customDifficulty <= 0.7 && customDifficulty > 0.5) {
		return "Intermedia alta";
	} else if (customDifficulty <= 0.5 && customDifficulty > 0.3) {
		return "Difícil";
	} else if (customDifficulty <= 0.3 && customDifficulty !== null) {
		return "Muy difícil";
	} else {
		return "none";
	}
}

export function getDifficultColorByDifficult(difficult: string) {
	if ("very-easy") {
		return "#00FF00";
	} else if ("easy") {
		return "#e3ff00";
	} else if ("medium-low") {
		return "#c6ce00";
	} else if ("medium-high") {
		return "#FFC000";
	} else if ("hard") {
		return "#ff4000";
	} else if ("expert") {
		return "#8B0000";
	} else {
		return "#0000000";
	}
}

// Función para calcular los rangos de fechas
export const calculatePreviewDateRange = (periodType: TimePeriod) => {
	const now = new Date();
	const today = startOfDay(now);

	// Función helper para ajustar la zona horaria
	const toLocalISOString = (date: Date) => {
		const offset = date.getTimezoneOffset() * 60000;
		return new Date(date.getTime() - offset).toISOString();
	};

	switch (periodType) {
		case TimePeriod.PREVIOUS_DAY: {
			const yesterday = subDays(today, 1);
			const start = startOfDay(yesterday);
			const end = endOfDay(today);

			return {
				include: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
				exclude: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
			};
		}

		case TimePeriod.PREVIOUS_WEEK: {
			const weekAgo = subWeeks(today, 1);
			const start = startOfDay(weekAgo);
			const end = endOfDay(today); // Usar today
			return {
				include: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
				exclude: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
			};
		}

		case TimePeriod.PREVIOUS_MONTH: {
			const monthAgo = subMonths(today, 1);
			const start = startOfDay(monthAgo);
			const end = endOfDay(today); // Usar today
			return {
				include: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
				exclude: {
					startDate: toLocalISOString(start),
					endDate: toLocalISOString(end),
				},
			};
		}
		default:
			return {
				include: {
					startDate: "",
					endDate: "",
				},
				exclude: {
					startDate: "",
					endDate: "",
				},
			};
	}
};
