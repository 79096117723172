import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import firebase from "firebase/compat/app";

export const useGenerations = (compareGenerations, announcements?) => {
	const [gens, setGens] = useState<any>([]);
	const [gensRecord, setgensRecord] = useState<any>([]);
	const [load, setLoad] = useState(true);
	const [gensCopy, setGensCopy] = useState([]);
	const [filter, setFilter] = useState<string>("active");
	const [filterEs, setFilterEs] = useState<string>("Activas");
	const [filterStatus, setFilterStatus] = useState(false); // 'true' para activas, 'false' para desactivadas
	const [eyeColor, setEyeColor] = useState<string>("green");
	// ... Otros estados

	const filtering = useCallback(
		async (array, filter) => {
			let currentDay = new Date();
			const day = String(currentDay.getDate()).padStart(2, "0");
			const month = String(currentDay.getMonth() + 1).padStart(2, "0");

			const date = `${day}/${month}/${currentDay.getFullYear()}`;

			if (array.length > 0) {
				let tempGens: any = [];
				if (filter === "active") {
					array.forEach((element) => {
						if (
							element.futureMaterials > 0 &&
							moment(date, "DD-MM-YYYY").isSameOrAfter(moment(element.initialDate, "DD-MM-YYYY"))
						) {
							tempGens.push(element);
						}
					});
				} else if (filter === "past") {
					array.forEach((element) => {
						if (
							element.futureMaterials === 0 &&
							moment(date, "DD-MM-YYYY").isAfter(moment(element.initialDate, "DD-MM-YYYY"))
						) {
							tempGens.push(element);
						}
					});
				} else if (filter === "future") {
					array.forEach((element) => {
						if (
							element.futureMaterials > 0 &&
							moment(date, "DD-MM-YYYY").isBefore(moment(element.initialDate, "DD-MM-YYYY"))
						) {
							tempGens.push(element);
						}
					});
				} else {
					tempGens = array.map((x) => x);
				}
				setGens(tempGens);
			}
		},
		[setGens]
	);

	const handleSelect = useCallback(
		async (event) => {
			const filter = event;
			switch (filter) {
				case "active":
					setFilter("active");
					setEyeColor("green");
					setFilterEs("Activas");
					filtering(gensCopy, filter);
					break;
				case "all":
					setFilter("all");
					setEyeColor("orange");
					setFilterEs("Todas");
					filtering(gensCopy, filter);
					break;
				case "past":
					setFilter("past");
					setEyeColor("");
					setFilterEs("Pasadas");
					filtering(gensCopy, filter);
					break;
				case "future":
					setFilter("future");
					setEyeColor("blue");
					setFilterEs("Próximas");
					filtering(gensCopy, filter);
					break;
				default:
					break;
			}
		},
		[gensCopy, filtering]
	);

	const handleCheck = useCallback(
		(filter) => {
			switch (filter) {
				case "active":
					setFilter("active");
					setEyeColor("green");
					setFilterEs("Activas");
					filtering(gensCopy, filter);
					break;
				case "all":
					setFilter("all");
					setEyeColor("orange");
					setFilterEs("Todas");
					filtering(gensCopy, filter);

					break;
				case "past":
					setFilter("past");
					setEyeColor("");
					setFilterEs("Pasadas");
					filtering(gensCopy, filter);
					break;
				case "future":
					setFilter("future");
					setEyeColor("blue");
					setFilterEs("Próximas");
					filtering(gensCopy, filter);
					break;
				default:
					break;
			}
		},
		[gensCopy, filtering]
	);

	const materialCount = useCallback(async (array) => {
		if (array.length > 0) {
			for (const element of array) {
				let materials = element.materials;
				let oldMaterials = 0;
				let futureMaterials = 0;

				if (materials) {
					for (const material of materials) {
						let materialDate = new Date(
							+material.date.split("/")[2],
							+material.date.split("/")[1],
							+material.date.split("/")[0]
						);
						const today = new Date();
						if (today > materialDate) {
							oldMaterials++;
						} else {
							futureMaterials++;
						}
					}
				}

				element.oldMaterials = oldMaterials;
				element.futureMaterials = futureMaterials;
			}
		}
	}, []);

	const getGenerations = useCallback(async () => {
		setLoad(true);
		let array: any = [];
		const q = query(collection(db, "generations"));
		const querySnapshot = await getDocs(q);
		let promises: any = []; // Aquí almacenamos todas las promesas

		querySnapshot.forEach((doc) => {
			const q2 = query(collection(db, "users"), where("generationId", "==", doc.id));
			const promise = getDocs(q2).then((querySnapshot2) => {
				const data: any = {
					...doc.data(),
					id: doc.id,
					numStudents: querySnapshot2.size,
				};
				array.push(data);

				array.sort(compareGenerations);

				let tempMaterials: any = [];
				const qMaterial = query(collection(db, "generations", data.id, "material"));
				const promiseMaterial = getDocs(qMaterial).then((querySnapshotMaterial) => {
					querySnapshotMaterial.forEach((doc2) => {
						const data2 = {
							...doc2.data(),
							id: doc2.id,
						};
						tempMaterials.push(data2);
					});

					data.materials = tempMaterials; // Asignamos los materiales a la generación correspondiente
				});
				return promiseMaterial;
			});

			promises.push(promise);
		});

		// Usamos Promise.all() para asegurarnos de que todas las promesas hayan terminado antes de continuar
		Promise.all(promises).then(() => {
			setGens(array);
			setgensRecord(array);
			setGensCopy(array.map((x) => x));
			materialCount(array);
			filtering(array, filter);
			setLoad(false);
			handleSelect(filter);
		});
	}, [compareGenerations, materialCount, filtering]);

	useEffect(() => {
		getGenerations();
	}, [getGenerations]);

	// Verificar si announcements existe antes de intentar filtrarlos
	const filteredAnnouncements = announcements
		? announcements.filter((announcement) => {
				const generation = gens.find((e) => e.id === announcement.generationId);
				return !!generation; // returns true if generation exists, false otherwise
		  })
		: []; // si announcements es undefined, filteredAnnouncements será una matriz vacía

	return {
		gens,
		gensCopy,
		setFilter,
		handleSelect,
		filterEs,
		handleCheck,
		filteredAnnouncements,
		load,
		eyeColor,
		setGens,
		gensRecord,
		getGenerations,
		setEyeColor,
	};
};

export const useGenerationsAndMaterials = (compareGenerations, user?, compare2?, compare3?) => {
	const [gens, setGens] = useState<any>([]);
	const [load, setLoad] = useState(true);
	const [gensCopy, setGensCopy] = useState([]);
	const [filter, setFilter] = useState<string>("active");
	const [filterEs, setFilterEs] = useState<string>("Activas");
	const [filterStatus, setFilterStatus] = useState(false); // 'true' para activas, 'false' para desactivadas
	const [eyeColor, setEyeColor] = useState<string>("green");

	const filtering = async (array, filter) => {
		let currentDay = new Date();
		const day = String(currentDay.getDate()).padStart(2, "0");
		const month = String(currentDay.getMonth() + 1).padStart(2, "0");

		const date = `${day}/${month}/${currentDay.getFullYear()}`;
		if (array.length > 0) {
			let tempGens: any = [];
			if (filter === "active") {
				array.forEach((element) => {
					if (
						element.futureMaterials > 0 &&
						moment(date, "DD-MM-YYYY").isSameOrAfter(moment(element.initialDate, "DD-MM-YYYY"))
					) {
						tempGens.push(element);
					}
				});
			} else if (filter === "past") {
				array.forEach((element) => {
					//	console.log("element.futureMaterials", element.futureMaterials, element);
					if (
						element.futureMaterials === 0 &&
						moment(date, "DD-MM-YYYY").isAfter(moment(element.initialDate, "DD-MM-YYYY"))
					) {
						tempGens.push(element);
					}
				});
			} else if (filter === "future") {
				array.forEach((element) => {
					if (
						element.futureMaterials > 0 &&
						moment(date, "DD-MM-YYYY").isBefore(moment(element.initialDate, "DD-MM-YYYY"))
					) {
						tempGens.push(element);
					}
				});
			} else {
				tempGens = array.map((x) => x);
			}
			setGens(tempGens);
		}
	};

	const handleSelect = async (event) => {
		const filter = event;
		switch (filter) {
			case "active":
				setFilter("active");
				setEyeColor("green");
				setFilterEs("Activas");
				filtering(gensCopy, filter);
				break;
			case "all":
				setFilter("all");
				setEyeColor("orange");
				setFilterEs("Todas");
				filtering(gensCopy, filter);
				break;
			case "past":
				setFilter("past");
				setEyeColor("");
				setFilterEs("Pasadas");
				filtering(gensCopy, filter);
				break;
			case "future":
				setFilter("future");
				setEyeColor("blue");
				setFilterEs("Próximas");
				filtering(gensCopy, filter);
				break;
			default:
				break;
		}
	};

	const handleCheck = useCallback(
		(filter) => {
			switch (filter) {
				case "active":
					setEyeColor("green");
					filtering(gensCopy, filter);
					break;
				case "all":
					setEyeColor("orange");
					filtering(gensCopy, filter);
					break;
				case "past":
					setEyeColor("");
					filtering(gensCopy, filter);
					break;
				case "future":
					setEyeColor("blue");
					filtering(gensCopy, filter);
					break;
				default:
					break;
			}
		},
		[gensCopy, filtering]
	);

	const materialCount = async (array) => {
		if (array.length > 0) {
			for (const element of array) {
				let materials = element.seminarios;
				let oldMaterials = 0;
				let futureMaterials = 0;

				if (materials) {
					for (const material of materials) {
						let dateParts = material.date.split("/");
						let materialDate = new Date(
							parseInt(dateParts[2]),
							parseInt(dateParts[1]),
							parseInt(dateParts[0])
						);
						const today = new Date();
						today.setHours(0, 0, 0, 0);

						if (today > materialDate) {
							oldMaterials++;
						} else {
							futureMaterials++;
						}
					}
				}

				element.oldMaterials = oldMaterials;
				element.futureMaterials = futureMaterials;
			}
		}
	};

	const getGenerations = async () => {
		setLoad(true);
		setGens([]);
		handleSelect(filter);
		let array: any = [];

		const q = query(collection(db, "generations"));
		const querySnapshot = await getDocs(q);
		let i = 0;
		await Promise.all(
			querySnapshot.docs.map(async (doc) => {
				const q2 = query(collection(db, "users"), where("generationId", "==", doc.id));
				const querySnapshot2 = await getDocs(q2);

				const data = {
					...doc.data(),
					id: doc.id,
					numStudents: querySnapshot2.size,
				};
				if (user.userType === 2 || user.userType === 4) {
					array.push(data);
				} else {
					if (user.userType === 3 && doc.id === user.generationId) {
						array.push(data);
					}
				}
				i++;
			})
		);
		array.sort(compareGenerations);
		getSeminaries(array);
	};

	const getSeminaries = async (data) => {
		let arraygens: any = [];
		let index = 0;
		let pastMaterial: any = [];
		const date = new Date();
		data.forEach(async (gen) => {
			let seminariesArray: any = [];
			let nextMaterial: any = [];

			const q = query(collection(db, "generations", gen.id, "material"));

			const querySnapshot = await getDocs(q);

			let i = 0;

			querySnapshot.forEach((doc) => {
				i++;
				// doc.data() is never undefined for query doc snapshots
				const data2: any = {
					...doc.data(),
					id: doc.id,
				};
				seminariesArray.push(data2);
				//console.log("semonaries " + data2);
				let materialDate = new Date();

				let dateS = data2.date.split("/");

				dateS[1] = parseInt(dateS[1]) + "";
				materialDate.setDate(dateS[0]);
				materialDate.setMonth(dateS[1]);
				materialDate.setFullYear(dateS[2]);
				materialDate.setHours(0, 0, 0);

				if (date < materialDate) {
					nextMaterial.push(data2);
				}

				if (i === querySnapshot.size) {
					seminariesArray.sort(compare2);
					nextMaterial.sort(compare3);
					arraygens.push({ ...gen, seminarios: seminariesArray, nextSeminaries: nextMaterial });
				}
			});
			index++;
			setLoad(false);
			if (index === data.length) {
				setGens(arraygens);
				setGensCopy(arraygens.map((copyData) => copyData));
				materialCount(arraygens);

				filtering(arraygens, filter);
			}
		});
		// setCheck(true);
		// setRefresh(!refresh)
	};

	return {
		gens,
		gensCopy,
		setFilter,
		handleSelect,
		filterEs,
		getGenerations,
		getSeminaries,
		handleCheck,
		load,
		eyeColor,
		setGens,
		setEyeColor,
	};
};
